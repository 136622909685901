import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

const Clients = () => {
  const clients = [
    {
      id: 1,
      name: 'ABC Company',
      description: 'A company that specializes in marketing and branding.',
      img: 'https://media.istockphoto.com/id/1290692464/es/foto/trabajo-en-equipo-red-y-concepto-comunitario.jpg?s=612x612&w=is&k=20&c=x-JkgNt6Lv83cgBxSm3G3llKl5ikces4Mp7AgWx-Wrg=',
    },
    {
      id: 2,
      name: 'XYZ Inc.',
      description: 'A tech company that develops software and apps.',
      img: 'https://www.shutterstock.com/image-vector/rice-logo-design-vector-1194798991',
    },
    {
      id: 3,
      name: '123 Corp.',
      description: 'A financial institution that provides banking services.',
      img: 'https://www.shutterstock.com/image-vector/wheat-logo-symbol-icon-template-1482893102',
    },
  ];

  const renderClients = () => {
    return clients.map((client) => (
      <Col sm={6} md={4} lg={3} key={client.id}>
        <Card className="client-card border-0 text-center">
          <div className="client-logo">{client.img}</div>
          <Card.Body>
            <Card.Title className="client-name">{client.name}</Card.Title>
            <Card.Text className="client-description">
              {client.description}
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
    ));
  };

  return (
    <div className="clients-section">
      <Container>
        <h2 className="section-title">Our Clients</h2>
        <Row>{renderClients()}</Row>
      </Container>
    </div>
  );
};

export default Clients;
