import React from "react";
import { useState } from "react";
import logo2 from "./bey-logo.png";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Nav() {
  let [open, setopen] = useState(false);

  return (
    <div className="bg-white font-serif">
      <nav className="flex items-center justify-between">
        <FontAwesomeIcon
          icon="bars"
          style={{ color: "#f0c219" }}
          className="md:hidden fixed pr-20 -right-16 mb-4 cursor-pointer h-6 z-20 top-2"
          onClick={() => setopen(!open)}
        />
        <div className="flex space-x-4">
        <img src={logo2} alt="logo" className="h-20 w-auto max-w-[100px] sm:max-w-[150px] md:max-w-[200px]" />
        <h2 className="md:mt-6 text-cyan-600 font-semibold text-2xl ">
           BEYONDBITS INNOVATION LTD
          </h2>
        </div>

        <ul
          className={`bg-white backdrop-blur-md  md:static fixed duration-500 ease-linear top-0 md:h-auto h-screen z-10 ${
            !open ? "right-[-300%]" : "right-0"
          }`}
        >
          <li className="md:inline-block md:ml-10 ml-5 md:my-0 my-4 border-b-2 border-cyan-600 hover:border-cyan-600 duration-300">
            <a
              href="/"
              className="text-cyan-600 text-lg cursor-pointer font-Barlow font-normal inline-block md:py-2"
            >
              Home
            </a>
          </li>
          <li className="md:inline-block md:ml-10 ml-5 md:my-0 my-4 border-b-2 border-cyan-600 hover:border-cyan-600 duration-300">
            <a
              href="/about"
              className="text-cyan-600 text-lg  cursor-pointer font-Barlow font-normal inline-block md:py-2"
            >
              About Us
            </a>
          </li>
          <li className="md:inline-block md:ml-10 ml-5 md:my-0 my-4 border-b-2 border-cyan-600 hover:border-cyan-600 duration-300">
            <a
              href="/services"
              className="text-cyan-600 text-lg cursor-pointer font-Barlow font-normal inline-block  md:py-2"
            >
              Services
            </a>
          </li>
          <li className="md:inline-block md:ml-10 ml-5 md:my-0 my-4 border-b-2 border-cyan-600 hover:border-cyan-600 duration-300">
            <a
              href="/portfolio"
              className="text-cyan-600 text-lg cursor-pointer font-Barlow font-normal inline-block md:py-2"
            >
              Portfolio
            </a>
          </li>
          <li className="md:inline-block md:ml-10 ml-5 md:my-0 my-4 border-b-2 border-cyan-600 hover:border-cyan-600 duration-300">
            <a
              href="/conta"
              className="text-cyan-600 text-lg cursor-pointer font-Barlow font-normal inline-block md:py-2"
            >
              Contact
            </a>
          </li>
          <li className="group relative md:inline-block pl-6 mt-8  duration-300">
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="inline-flex w-32 justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                  Community
                  <ChevronDownIcon
                    className="-mr-1 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-cyan-950 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="/community"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-cyan-600",
                            "block px-4 py-2 text-sm"
                          )}
                        >
                          COMMUNITY
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="/testimonial"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-cyan-600",
                            "block px-4 py-2 text-sm"
                          )}
                        >
                          TESTIMONIAL
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="/team"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-cyan-600",
                            "block px-4 py-2 text-sm"
                          )}
                        >
                          TEAM
                        </a>
                      )}
                    </Menu.Item>
                    {/* <form method="POST" action="#">
              <Menu.Item>
                {({ active }) => (
                  <button
                    type="submit"
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block w-full px-4 py-2 text-left text-sm'
                    )}
                  >
                    Sign out
                  </button>
                )}
              </Menu.Item>
            </form> */}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Nav;
