import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import Lottie from "react-lottie";
import animationData from "./2yheObc7FT.json";

import {
  FaCode,
  FaLaptop,
  FaMobileAlt,
  FaDatabase,
  FaNetworkWired,
  FaUserTie,
  FaServer,
  FaFileAlt,
  FaGraduationCap,
} from "react-icons/fa";

const Services = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const services = [
    {
      id: 1,
      title: "Web Development",
      description:
        "We build responsive, user-friendly websites for business showcases, eCommerce, blogs, entertainment, news, nonprofits, portfolios, and personal websites—all utilizing the latest technologies.",
      icon: <FaCode size={60} />,
    },
    {
      id: 2,
      title: "System Development",
      description:"We offer customized software solutions, including ERP for streamlined operations, CRM for customer engagement, SCM for logistics optimization, HRMS for personnel management, Inventory Management for stock control, secure E-Commerce, Financial Management, comprehensive Healthcare systems, and LMS for effective education.",
      icon: <FaLaptop size={60} />,
    },
    {
      id: 8,
      title: "Graphic Design",
      description:
        "At BeyondBits, We specialize in graphic design, offering unique logo creations, eye-catching print materials like business cards and banners, dynamic digital visuals, intuitive website and app interfaces, personalized birthday and wedding cards, and professional CV design to elevate your personal brand.",
      icon: <FaFileAlt size={60} />,
    },
    // {
    //   id: 3,
    //   title: 'Mobile App Development',
    //   description:
    //     'We create mobile apps for iOS and Android platforms that engage users.',
    //   icon: <FaMobileAlt size={60} />,
    // },

    {
      id: 7,
      title: "Web Hosting",
      description:
        "At BeyondBits Innovation Ltd., we provide robust web hosting solutions, including server space, guaranteed uptime, domain registration, secure SSL transmission, reliable data storage, email hosting, and expert support—ensuring a powerful online presence and optimal website performance.",
      icon: <FaServer size={60} />,
    },

    // {
    //   id: 6,
    //   title: "IT Consulting",
    //   description:
    //     "At HBA TechConsult, our IT consulting services encompass a wide spectrum, including technology strategy, digital transformation, cloud solutions, cybersecurity, data analytics, project management, software development consultation, disaster recovery planning, managed services, network design, compliance, UX/UI guidance, vendor selection, training, assessments, and virtual CIO services. We empower businesses to optimize their IT infrastructure, enhance security, streamline operations, and drive innovation.",
    //   icon: <FaUserTie size={60} />,
    // },

    // {
    //   id: 4,
    //   title: "Database Management",
    //   description:
    //     "We provide database design, development, and administration services.",
    //   icon: <FaDatabase size={60} />,
    // },
    // {
    //   id: 5,
    //   title: "Network Configuration",
    //   description:
    //     "We provide network configuration and setup services for businesses and organizations.",
    //   icon: <FaNetworkWired size={60} />,
    // },

    // {
    //   id: 9,
    //   title: "Education and Training",
    //   description:
    //     "We offer education and training services to help businesses and individuals improve their technology skills.",
    //   icon: <FaGraduationCap size={60} />,
    // },
  ];

  const renderServices = () => {
    return services.map((service) => (
      <Col md={6} lg={3} key={service.id}>
        <Card className="service-card md:h-[400px] bg-white mt-4 mb-6 text-center">
          <Card.Body>
            <div className="md:flex w-full text-cyan-300 justify-center items-center">
              {service.icon}
            </div>
            <Card.Title className="service-title pt-2 font-semibold text-xl">{service.title}</Card.Title>
            <Card.Text className="service-description pt-2">
              {service.description}
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
    ));
  };

  return (
    <div className="font-serif">
      <div className=" bg-gradient-to-b from-gray-900 to-cyan-400 md:md:flex md:justify-between md:items-center">
        <div className="">
          {/* <div className="md:flex "> */}
          <h2 className="text-cyan-400 md:pl-8 font-bold decoration-wavy font-[-400] text-6xl">
          Services
          </h2>
          {/* </div> */}
          {/* <div className="md:flex opacity-870"> */}
          <p className=" text-white text-4xl p-10">
          Explore our services and discover  <br/>
          how BeyondBits Innovation Ltd.  <br/>
          can elevate your business with <br/>
          our cutting-edge, reliable,  <br/>
           and cost-effective IT solutions.
          </p>
          {/* </div> */}
        </div>
        <div className="md:flex">
          <Lottie
            animationData={animationData}
            options={defaultOptions}
            max-height={560}
            max-width={560}
            className=""
          />
        </div>
      </div>
      <div className="services-section " id="services">
        <div>
          <h2 className="text-center pt-8 pb-3 text-cyan-300 text-5xl underline decoration-solid decoration-white underline-offset-8  font-semibold shadow-2xl">
            Our Services
          </h2>
          <div>
            {/* <Lottie animationData={animationData} options={defaultOptions} height={400} width={600} /> */}
          </div>
        </div>
        <Container>
          {/* <h2 className="section-title" id="service">
            Our Services
          </h2> */}
          <Row>{renderServices()}</Row>
        </Container>
      </div>

      <div className="md:pb-8 bg-gradient-to-r from-gray-600 to-cyan-300">
        <div className="pb-8 pt-4">
          <div>
            <h2 className="text-3xl font-bold text-center font-Nunito">
              Why Choose Us
            </h2>
          </div>
        </div>
        <div className="md:pl-4 md:pr-4 space-y-4 md:grid md:grid-cols-3">
          {/* <div className="md:md:flex md:justify-between md:items-center space-y-4 space-x-4"> */}
            <div className="md:flex space-x-4  transform transition duration-700 hover:scale-95  md:h-48  md:pl-4 md:pr-4 md:w-96">
              <div className="flex justify-normal">
                <h2>01</h2>
                <div class="h-1 w-28 mt-2 bg-cyan-300"></div>
              </div>
              <div>
                <h2 className="text-lg font-semibold font-Nunito">
                  High Quality Hardware
                </h2>
                <p className="font-Nunito">
                  We use top-norch hardware to develop
                  <br></br>the most efficient apps for our customers.
                </p>
              </div>
            </div>
            <div className="md:flex space-x-4 transform transition duration-700 hover:scale-95 md:h-48  md:pl-4 md:pr-4 md:w-96">
              <div className="flex justify-normal">
                <h2>02</h2>
                <div class="h-1 w-28 mt-2 bg-cyan-300"></div>
              </div>
              <div>
                <h2 className="text-lg font-semibold font-Nunito">
                  Expertise and Experience:
                </h2>
                <p className="font-Nunito">
                  With a highly skilled team of IT professionals,
                  <br />
                  we have a proven track record of delivering
                  <br />
                  successful projects across various industries.
                </p>
              </div>
            </div>
            <div className="md:flex space-x-4 transform transition duration-700 hover:scale-95  md:h-48  md:pl-4  md:pr-4 md:w-96">
              <div className="flex justify-normal">
                <h2>03</h2>
                <div class="h-1 w-28 mt-2 bg-cyan-300"></div>
              </div>
              <div>
                <h2 className="text-lg font-semibold">End-to-End Services:</h2>
                <p>
                  By partnering with us,
                  <br />
                  you gain access to a single
                  <br />
                  point of contact for all your
                  <br />
                  IT requirements.
                </p>
              </div>
            </div>
          {/* </div> */}
          {/* <div className="md:md:flex md:justify-between md:items-center space-y-4 space-x-4"> */}
            <div className="md:flex space-x-4 transform transition duration-700 hover:scale-95  md:h-48 md:pl-4  md:pr-4 md:w-96">
              <div className="flex justify-normal">
                <h2>04</h2>
                <div class="h-1 w-28 mt-2 bg-cyan-300"></div>
              </div>
              <div>
                <h2 className="text-lg font-semibold">Innovative Solutions</h2>
                <p>
                  Our innovative mindset
                  <br />
                  enables us to think outside <br />
                  the box and find creative solutions to complex challenges,
                  driving your
                  <br />
                  digital transformation forward.
                </p>
              </div>
            </div>
            <div className="md:flex space-x-4  transform transition duration-700 hover:scale-95  md:h-48 md:pr-4 md:w-96">
              <div className="flex justify-normal">
                <h2>05</h2>
                <div class="h-1 w-28 mt-2 bg-cyan-300"></div>
              </div>
              <div>
                <h2 className="text-lg font-semibold">
                  Dedicated 24/7 support
                </h2>
                <p>
                  You can rely on our <br />
                  24/7 tech support
                  <br></br>that will respond faster and help on IT problems
                  encountered.
                </p>
              </div>
            </div>
            <div className="md:flex space-x-4 transform transition duration-700 hover:scale-95  md:h-48  md:pl-4 md:pr-4 md:w-96">
              <div className="flex justify-normal">
                <h2>06</h2>
                <div class="h-1 w-28 mt-2 bg-cyan-300"></div>
              </div>
              <div>
                <h2 className="text-lg font-semibold">Reliability</h2>
                <p>
                  We prioritize transparency in our
                  <br />
                  operations and strive to build <br />
                  long-term relationships based on trust.
                </p>
              </div>
            </div>
          {/* </div> */}
        </div>
        </div>
    </div>
  );
};

export default Services;
