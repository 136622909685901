import React from "react";

import Lottie from "react-lottie";
import animationData2 from "./112555-event-alert.json";

function News() {
  const defaultOptions1 = {
    loop: true,
    autoplay: true,
    animationData: animationData2,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const newsItems = [
    {
      id: 1,
      title: "New Feature Announcement",
      content: "We are excited to announce a new feature in our web!",
      date: "To be communicated",
    },
    {
      id: 2,
      title: "Upcoming Event",
      content: "No upcoming events for now",
      date: "",
    },
    {
      id: 3,
      title: "New Projects",
      content: "To be announced soon",
      date: "",
    },

    // Add more news items as needed
  ];

  return (
    <div className="p-4 bg-cyan-800 font-Nunito">
       <h2 className="flex text-5xl text-cyan-400 font-bold justify-center p-4">
        Latest News and Updates
      </h2>
      <div className="flex md:justify-center">
        <Lottie
          animationData={animationData2}
          options={defaultOptions1}
          height={400}
          width={400}
          className=""
        />
      </div>



      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {newsItems.map((item) => (
            <div className="bg-white rounded-lg shadow-lg p-4" key={item.id}>
              <h3 className="text-lg font-semibold mb-2">{item.title}</h3>
              <p className="text-gray-700">{item.content}</p>
              <p className="text-sm text-gray-500 mt-2">
                Published on: {item.date}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default News;
