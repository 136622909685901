// import logo from "./logo.jpeg";
import "./App.css";
import React from "react";
// import Header from "./Header";
import Nav from "./Nav";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Services from "./Services";
// import Talk from "./Talk.jsx";
import AboutUs from "./AboutUs.jsx";
import { Routes, Route, useLocation } from "react-router-dom";
import HomeUs from "./HomeUs.jsx";
// import ContactUs from "./ContactUs";
// import Footer from "./Footer";
import "animate.css";
import Community from "./Community";
import { useState, useEffect } from "react";
import BarLoader from "react-spinners/BarLoader";
import Portfolio from "./Portfolio.jsx";
import Team from "./Team";
import Testimonial from "./Testimonial";
import Clients from "./Clients";
import Contact from "./Contact";
import { useCallback } from "react";
// import Particles from "react-tsparticles";
// import { loadFull } from "tsparticles";
import Faqs from "./Faqs";
import News from "./News";
import Footer1 from "./Footer1";
// import the library
import { library } from "@fortawesome/fontawesome-svg-core";

// import your icons
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

// const AnimatedRoutes = () => {
//   const location = useLocation();

//   return (
//     <TransitionGroup>
//       <CSSTransition key={location.key} timeout={1000} classNames="page">
//         <Routes location={location}>
//           <Route index element={<HomeUs />} />
//           <Route path="/about" element={<AboutUs />} />
//           <Route path="/contact" element={<ContactUs />} />
//         </Routes>
//       </CSSTransition>
//     </TransitionGroup>
//   );
// };

function App() {
  // const override: CSSProperties = {
  //   display: "block",
  //   margin: "0 auto",
  //   borderColor: "red",

  // };

  // const particlesInit = useCallback(async (engine) => {
  //   console.log(engine);
  // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
  // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
  // starting from v2 you can add only the features you need reducing the bundle size
  //   await loadFull(engine);
  // }, []);

  // const particlesLoaded = useCallback(async (container) => {
  //   await console.log(container);
  // }, []);

  // const [loading, setLoading] = useState(false);
  // useEffect(() => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 5000);
  // }, []);

  return (
    <div className="App">
      <div>
        <Nav />

        <Routes>
          <Route path="/" element={<HomeUs />}></Route>
          {/* <Route path="/talk" element={<Talk />}></Route> */}
          <Route path="/services" element={<Services />}></Route>
          <Route path="/about" element={<AboutUs />}></Route>
          <Route path="/community" element={<Community />}></Route>
          <Route path="/portfolio" element={<Portfolio />}></Route>
          <Route path="/team" element={<Team />}></Route>
          <Route path="/testimonial" element={<Testimonial />}></Route>
          <Route path="/clients" element={<Clients />}></Route>
          <Route path="/conta" element={<Contact />}></Route>
          <Route path="/faqs" element={<Faqs />}></Route>
          <Route path="/news" element={<News />}></Route>
        </Routes>
        {/* <AnimatedRoutes /> */}
        {/* <Footer />
          <Header /> */}
        <Footer1 />
      </div>
    </div>
  );
}

export default App;
library.add(fab, fas, far);
