import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { FaFacebook, FaTwitter, FaLinkedin } from "react-icons/fa";
// import ContactUS from "./ContactUs";
import { Link } from "react-router-dom";
import Feeds from "./Feeds";
import News from "./News";
import Lottie from "react-lottie";
import animationData2 from "./Learning community.json";

const Community = () => {
  const defaultOptions1 = {
    loop: true,
    autoplay: true,
    animationData: animationData2,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="community-container font-Nunito bg-gradient-to-bl from-gray-800 to-cyan-300">
      <h2 className="text-center text-5xl text-cyan-400 pt-8 pb-10 font-semibold">
        TechConnect
      </h2>
      <div className="md:grid md:grid-cols-2">
        <div className="">
          <p className="text-center text-white text-4xl md:p-4">
            Welcome to TechConnect, your gateway to a <br />
            vibrant community where tech enthusiasts,
            <br />
            customers, and industry professionals come
            <br />
            together. Join us to share feedback, explore  <br />
            frequently asked questions, participate in
            <br />
            engaging forums, and keep updated on our latest events.
          </p>
          <div className="flex pt-6">
            <Lottie
              animationData={animationData2}
              options={defaultOptions1}
              max-height={550}
              width={550}
              className=""
            />
          </div>
        </div>
        <div className="md:grid md:grid-cols-1 gap-4 md:pl-8 ">
          <div className=" md:ml-20 md:p-18 space-y-10">
            {/* <div>
              <h2 className="uppercase font-semibold text-2xl text-white">
                Forums and Discussion Boards
              </h2>
              <p className="text-xl ">Connect with other users and get your questions answered.</p>
              <Button
                className="focus:outline-none text-black text-lg bg-cyan-400 hover:bg-cyan-500 focus:ring-4 focus:ring-cyan-300 font-medium rounded-lg  px-5 py-2.5 mr-2 mb-2 w-72 dark:focus:ring-cyan-900"
                href="#"
              >
                Join the discussion
              </Button>
            </div> */}

            <div className="pt-33">
              <h2 className="uppercase font-semibold text-2xl text-white">Knowledge Base</h2>
              <p className="text-xl" >
                Find answers to your questions in our collection of articles and
                FAQs.
              </p>
              <Link to="/faqs">
                <Button className="focus:outline-none text-black text-lg bg-cyan-400 hover:bg-cyan-500 focus:ring-4 focus:ring-cyan-300 font-medium rounded-lg px-5 py-2.5 mr-2 mb-2  w-72 dark:focus:ring-cyan-900">
                  View the Knowledge Base
                </Button>
              </Link>
            </div>

            <div className="gap-2 p-18">
              <h2 className="uppercase font-semibold text-2xl text-white">Events Calendar</h2>
              <p className="text-xl" >Stay up-to-date on our upcoming webinars and other events.</p>
              <Link to="/news">
                <Button className="focus:outline-none text-black text-lg bg-cyan-400 hover:bg-cyan-500 focus:ring-4 focus:ring-cyan-300 font-medium rounded-lg  px-5 py-2.5 mr-2 mb-2 w-72 dark:focus:ring-cyan-900">
                  View the Events Calendar
                </Button>
              </Link>
            </div>

            <div className="gap-2 p-18">
              <h2 className="uppercase font-semibold text-2xl text-white">Feedback Forms</h2>
              <p className="text-xl" >Share your thoughts and help us improve.</p>
              <Link to="/conta">
                <Button className="focus:outline-none text-black text-lg bg-cyan-400 hover:bg-cyan-500 focus:ring-4 focus:ring-cyan-300 font-medium rounded-lg  px-5 py-2.5 mr-2 mb-2 w-72 dark:focus:ring-cyan-900">
                  Provide Feedback
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Community;
