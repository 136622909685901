import Husika from "./download.jpeg";
import Kanza from "./imarikah.png";
import MedAid from "./medaid.jpeg";

import {
  Card,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react";
import { RocketLaunchIcon } from "@heroicons/react/24/solid";
import { ArrowLongRightIcon } from "@heroicons/react/24/outline";
function Portfolio() {
  return (
    <div className="bg-gradient-to-b from-gray-800 to-cyan-300 font-Nunito">
      <div className="items-center ">
        <h2 className="text-center pt-6 text-5xl text-semibold text-cyan-300">
          Our Work
        </h2>
      </div>
      <div className="">
        <p className="text-center pt-6 text-3xl text-white">
          Our IT business is proud of the wide range of projects that
          demonstrate
          <br />
          our knowledge and abilities. Numerous innovative solutions, including
          web <br />
          development, mobile applications, data analytics, cloud computing, and
          others,
          <br />
          have been successfully delivered by us.{" "}
        </p>
      </div>
      <div className="md:flex md:justify-between md:items-center md:pl-8 md:pr-8 md:pb-6 pb-6">
        <Card className="mt-6 md:w-96 rounded-lg transform transition duration-700 hover:scale-95 shadow-lg flex flex-col items-center justify-center">
          <CardBody className="flex flex-col items-center justify-center text-center">
            <img src={Husika} className="text-cyan-500 md:w-20 h-20 mb-4" />
            <Typography variant="h5" color="cyan-gray" className="mb-2">
              Husika Alliance
            </Typography>
            {/* <Typography>
      Because it's about motivating the doers. Because I'm here to
      follow my dreams and inspire others.
    </Typography> */}
          </CardBody>
          <CardFooter className="pt-0">
            <a href="https://husikaalliance.org" className="inline-block" target="_blank">
              <Button
                size="sm"
                variant="text"
                className="flex items-center gap-2"
              >
                View Website
                <ArrowLongRightIcon strokeWidth={2} className="md:w-4 h-4" />
              </Button>
            </a>
          </CardFooter>
        </Card>

        <Card className="mt-6 md:w-96 rounded-lg transform transition duration-700 hover:scale-95 shadow-lg flex flex-col items-center justify-center">
          <CardBody className="flex flex-col items-center justify-center text-center">
            <img src={Kanza} className="text-cyan-500 md:w-32 h-20 mb-4" />
            <Typography variant="h5" color="cyan-gray" className="mb-2">
              Imarikah Roofing Solutions
            </Typography>
            {/* <Typography>
      Because it's about motivating the doers. Because I'm here to
      follow my dreams and inspire others.
    </Typography> */}
          </CardBody>
          <CardFooter className="pt-0">
            <a href="https://imarikah.com/" className="inline-block" target="_blank">
              <Button
                size="sm"
                variant="text"
                className="flex items-center gap-2"
              >
                View Website
                <ArrowLongRightIcon strokeWidth={2} className="md:w-4 h-4" />
              </Button>
            </a>
          </CardFooter>
        </Card>


        <Card className="mt-6 md:w-96 rounded-lg transform transition duration-700 hover:scale-95 shadow-lg flex flex-col items-center justify-center">
          <CardBody className="flex flex-col items-center justify-center text-center">
            <img src={MedAid} className="text-cyan-500 md:w-20 h-20 mb-4" />
            <Typography variant="h5" color="cyan-gray" className="mb-2">
              Medical Aid Australia
            </Typography>
            {/* <Typography>
      Because it's about motivating the doers. Because I'm here to
      follow my dreams and inspire others.
    </Typography> */}
          </CardBody>
          <CardFooter className="pt-0">
            <a href="https://www.medaid.org.au/home/" className="inline-block" target="_blank">
              <Button size="sm" variant="text" className="flex items-center gap-2">
                View Website
                <ArrowLongRightIcon strokeWidth={2} className="md:w-4 h-4" />
              </Button>
            </a>
          </CardFooter>
        </Card>


        {/* <Card className="mt-6 md:w-96 rounded-lg transform transition duration-700 hover:scale-95 shadow-lg">
            <CardBody>
              <RocketLaunchIcon className="text-cyan-500 md:w-20 h-20 mb-4" />
              <Typography variant="h5" color="cyan-gray" className="mb-2">
                Mandera Self-help Group
              </Typography>
              <Typography>
                Because it's about motivating the doers. Because I'm here to
                follow my dreams and inspire others.
              </Typography>
            </CardBody>
            <CardFooter className="pt-0">
              <a href="#" className="inline-block"  target="_blank">
                <Button
                  size="sm"
                  variant="text"
                  className="flex items-center gap-2"
                >
                  View Website
                  <ArrowLongRightIcon strokeWidth={2} className="md:w-4 h-4" />
                </Button>
              </a>
            </CardFooter>
          </Card> */}

      </div>
    </div>
  );
}

export default Portfolio;






















