import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Feeds from "./Feeds";
function Footer1() {
  return (
    <div className=" bg-gray-800 font-serif ">
      <div class="h-3 w-full mt-2 bg-cyan-400"></div>
      <div className="md:flex md:justify-between md:items-center lg:px-12 px-3 py-2">
        <div>
          <h6 className="text-cyan-400 text-2xl"> BEYONDBITS INNOVATION LTD</h6>

          <div className="pt-4">
            {/* <Feeds /> */}
          </div>
        </div>
        <div className="grid grid-rows-6 grid-flow-col   md:mt-4   ">
          <h2 className="text-white font-bold text-3xl pb-6">Links</h2>
          <Link
            to="/"
            className="hover:text-white duration-300 text-cyan-500
          text-lg cursor-pointer leading-6  underline decoration-solid decoration-white underline-offset-8 "
          >
            Home
          </Link>
          <Link
            to="/about"
            className="hover:text-white duration-300 text-cyan-500
          text-lg cursor-pointer leading-6 underline decoration-solid decoration-white underline-offset-8  "
          >
            About Us
          </Link>
          <Link
            to="/conta"
            className="hover:text-white duration-300 text-cyan-500
          text-lg cursor-pointer leading-6 underline decoration-solid decoration-white underline-offset-8 "
          >
            Contact Us
          </Link>
          <Link
            to="/portfolio"
            className="hover:text-white duration-300 text-cyan-500
          text-lg cursor-pointer leading-6 underline decoration-solid decoration-white underline-offset-8 "
          >
            Portfolio
          </Link>
        </div>
        {/* services */}
        <div className="grid grid-rows-6 grid-flow-col  md:mt-4">
          <h2 className="text-white font-bold text-3xl pb-6 ">Services</h2>
          <Link
            to="/services"
            className="hover:text-white duration-300 text-cyan-500
          text-lg cursor-pointer leading-6 underline decoration-solid decoration-white underline-offset-8 "
          >
            System Development
          </Link>
          <Link
            to="/services"
            className="hover:text-white duration-300 text-cyan-500
          text-lg cursor-pointer leading-6 underline decoration-solid decoration-white underline-offset-8 "
          >
            Web Development
          </Link>
          <Link
            to="/services"
            className="hover:text-white duration-300 text-cyan-500
          text-lg cursor-pointer leading-6 underline decoration-solid decoration-white underline-offset-8 "
          >
            Graphic Design
          </Link>
          <Link
            to="/services"
            className="hover:text-white duration-300 text-cyan-500
          text-lg cursor-pointer leading-6 underline decoration-solid decoration-white underline-offset-8 "
          >
            Web Hosting
          </Link>
        </div>

        <div className="grid grid-rows-6 grid-flow-col  md:mt-4">
          <h3 className="text-white font-bold text-3xl pb-6  ">
            Get In Touch With Us
          </h3>
          <div className="flex space-x-2">
            <FontAwesomeIcon
              icon="fa-solid fa-phone"
              style={{ color: "#FFFFFF" }}
            />
            <a href="tel:+254726541551" className="hover:text-white text-lg text-cyan-500">
              +254726541551
            </a>
          </div>

          <div className="flex space-x-4">
            <FontAwesomeIcon
              icon="fa-solid fa-envelope"
              style={{ color: "#FFFFFF" }}
            />
            <a
              href="mailto:info@beyondbitsinnovationltd.com"
              className="hover:text-white text-lg text-cyan-500"
            >
              info@beyondbitsinnovationltd.com
            </a>
          </div>

          <div className="flex space-x-4">
            <FontAwesomeIcon
              icon="fa-solid fa-location-dot"
              style={{ color: "#FFFFFF" }}
            />

            <a href="" className="hover:text-white text-lg text-cyan-500">
              Moi Avenue, Nairobi
            </a>
          </div>

          <div className="flex space-x-4">
            <a
              href=""
              target="_blank"
            >
              <FontAwesomeIcon
                icon="fa-brands fa-facebook"
                style={{ color: "#FFFFFF" }}
                size="2xl"
              />
            </a>
            <a href="https://x.com/beyondbits_ltd?s=11">
              <FontAwesomeIcon
                icon="fa-brands fa-twitter"
                style={{ color: "#FFFFFF" }}
                size="2xl"
              />
            </a>
            <a
              href="https://www.instagram.com/beyondbits_innovation_ltd?igsh=Y2NjN3diaDl6d3Zo&utm_source=qr"
              target="_blank"
            >
              <FontAwesomeIcon
                icon="fa-brands fa-square-instagram"
                style={{ color: "#FFFFFF" }}
                size="2xl"
              />
            </a>
          </div>
        </div>
      </div>
      <div>
        <h2 className="text-white text-center md:pb-4 text-lg">
          © Copyright BEYONDBITS INNOVATION LTD. 2024 All Rights Reserved
        </h2>
      </div>
    </div>
  );
}

export default Footer1;
