// import React from "react";
import React, { useEffect, useState } from "react";

import { Card, Col, Container, Row } from "react-bootstrap";
import Home from "./home3.avif";

import Testimonial from "./Testimonial";
import Mission from "./missi.png";
import Vission from "./viss.png";
import Objective from "./objec.png";

// Initialization for ES Users
// import { Animate, initTE } from "tw-elements";

import { TypeAnimation } from "react-type-animation";
// import Home from "./home.png";
import Lottie from "react-lottie";
import animationData from "./38834-service-animation.json";
import animationData1 from "./app-development-mobile.json";
import {
  FaCode,
  FaLaptop,
  FaMobileAlt,
  FaDatabase,
  FaNetworkWired,
  FaUserTie,
  FaServer,
  FaFileAlt,
  FaGraduationCap,
} from "react-icons/fa";

function HomeUs() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const defaultOptions1 = {
    loop: true,
    autoplay: true,
    animationData: animationData1,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const styles = {
    backgroundImage: `url(${Home})`,
    backgroundRepeat: "no-repeat",
    height: "700px",
  };
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(true);
  }, []);

  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    setShowContent(true);
  }, []);

  const services = [
    {
      id: 1,
      title: "Web Development",
      description:
        "We build responsive, user-friendly websites for business showcases, eCommerce, blogs, entertainment, news, nonprofits, portfolios, and personal websites—all utilizing the latest technologies.",
      icon: <FaCode size={60} />,
    },
    {
      id: 2,
      title: "System Development",
      description: "We offer customized software solutions, including ERP for streamlined operations, CRM for customer engagement, SCM for logistics optimization, HRMS for personnel management, Inventory Management for stock control, secure E-Commerce, Financial Management, comprehensive Healthcare systems, and LMS for effective education.",
      icon: <FaLaptop size={60} />,
    },
    {
      id: 3,
      title: "Graphic Design",
      description:
        "At BeyondBits, We specialize in graphic design, offering unique logo creations, eye-catching print materials like business cards and banners, dynamic digital visuals, intuitive website and app interfaces, personalized birthday and wedding cards, and professional CV design to elevate your personal brand.",
      icon: <FaFileAlt size={60} />,
    },
 
    {
      id: 8,
      title: "Web Hosting",
      description:
        "At BeyondBits Innovation Ltd., we provide robust web hosting solutions, including server space, guaranteed uptime, domain registration, secure SSL transmission, reliable data storage, email hosting, and expert support—ensuring a powerful online presence and optimal website performance.",
      icon: <FaServer size={60} />,
    },
 {
      id: 9,
      title: "Education and Training",
      description:
        "We offer education and training services to help businesses and individuals improve their technology skills.",
      icon: <FaGraduationCap size={60} />,
    },
  {
      id: 6,
      title: "IT Consulting",
      description:
        "At BeyondBits Innovation Ltd., we provide expert IT consulting services, from technology strategy and digital transformation to cybersecurity, cloud solutions, and managed services, empowering businesses to innovate and optimize their IT infrastructure.",
      icon: <FaUserTie size={60} />,
    },
  ];

  const renderServices = () => {
    return services.map((service) => (
      <Col md={6} lg={4} key={service.id}>
        <Card className="service-card md:h-[400px] bg-gray-100 mt-4 mb-6 text-center">
          <Card.Body>
            <div className="flex w-full text-cyan-300 justify-center items-center">
              {service.icon}
            </div>
            <Card.Title className="service-title pt-2 font-semibold text-xl">{service.title}</Card.Title>
            <Card.Text className="service-description pt-2 text-xl">
              {service.description}
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
    ));
  };

  //about
  const values = {
    value: [
      {
        name: "OUR MISSION",
        image: Mission,
        data: " To ignite innovation that transforms challenges into opportunities.",
      },
      {
        name: "OUR VISION",
        image: Vission,
        data: " To shape a future where technology weaves seamless connections in every life.",
      },
      {
        name: "OUR OBJECTIVES",
        image: Objective,
        data: " Craft pioneering solutions, equip minds to realize visions, lead a greener tech landscape, and unite talents for impactful change. ",
      },
    ],
  };

  return (
    <div className="font-Nunito">
  <div className="  bg-gradient-to-b from-gray-900 to-gray-500 min-h-screen md:w-full relative md:flex md:grid-cols-3 md:justify-center">
          {/* <Lottie animationData={animationData} options={defaultOptions} height={300} width={300} className='w-full h-screen object-cover absolute mix-blend-overlay' /> */}
          {/* <img src={Home} alt="" className="w-full h-screen object-cover absolute mix-blend-overlay"></img> */}
          <div className="md:col-span-2 space-y-10 py-14 pl-11">
            <h2 className="text-4xl md:text-5xl uppercase font-bold text-cyan-600 font-Nunito">
              <TypeAnimation
                sequence={[
                  // Same String at the start will only be typed once, initially
                  "BEYONDBITS  ",
                  1000,
                  "INNOVATION",
                  1000,
                  "LTD",
                  1000,
                ]}
                speed={50}
                style={{ fontSize: "5xl " }}
                text-5xl
                uppercase
                font-bold
                text-cyan-300
                repeat={Infinity}
              />
              Your All Stop
              <br />
              IT Solution
            </h2>
            <h2 className="text-2xl md:text-5xl text-zinc-200 font-Nunito">
            From bits to breakthroughs, <br/>
            we pioneer smarter solutions for a better tomorrow.
            </h2>
            <div className="flex space-x-3 mt-3 ">
              <a
                href="/services"
                className="font-Nunito inline-flex items-center px-4 py-2 text-2xl md:text-xl text-center text-black bg-cyan-300 rounded-lg hover:bg-cyan-500 focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:bg-cyan-300 dark:hover:bg-cyan-500 dark:focus:ring-cyan-300"
              >
                {/* purple box */}
                <span className="w-0 h-0 rounded bg-cyan-800 absolute top-0 left-0 ease-out duration-500 transition-all group-hover:w-full group-hover:h-full -z-1"></span>
                <span className="w-full text-black transition-colors duration-300 ease-in-out group-hover:text-white z-10 text-xl">
                  Our Service
                </span>
              </a>
            </div>
          </div>

          <div className="flex ">
            <Lottie
              animationData={animationData}
              options={defaultOptions}
              max-height={300}
              max-width={300}
              className=""
            />
          </div>

          {/* <img src={Home} alt="" className=""></img> */}
        </div>



      {/* About */}
      {/* <h2 className="text-bold text-center pt-3 text-black text-6xl">
        ABOUT US
      </h2>
      <div className=" md:flex md:justify-between md:items-center space-y-4 space-x-4 md:px-12 ">
        {values.value.map((member, index) => (
          <div
            key={index}
            className="   bg-white  rounded-lg p-3 animate-[fade-in_1s_ease-in-out]  transform transition duration-700 hover:scale-95 md:w-96 "
          >
            <div className=" bg-white border border-cyan-800 rounded-lg shadow dark:bg-gray-800 dark:border-cyan-900">
              <a href="#">
                <img
                  class="rounded-t-lg md:h-64 w-80 transition duration-300 ease-in-out hover:scale-95 "
                  src={member.image}
                  alt=""
                />
              </a>
              <div className="p-3 md:h-52 group block  mx-auto rounded-lg bg-white ring-1 ring-slate-900/5 shadow-lg  hover:bg-sky-500 hover:ring-sky-500">
                <div class="">
                  <svg
                    class="h-6 w-6 stroke-sky-500 group-hover:stroke-cyan-400"
                    fill="none"
                    viewBox="0 0 24 24"
                  ></svg>
                  <a href="#">
                    <h5 class="mb-2 text-2xl font-bold tracking-tight text-cyan-300 t group-hover:text-cyan-600 ">
                      {member.name}
                    </h5>
                  </a>
                </div>
                <p className="mb-4 font-light text-xl dark:text-gray-700 text-slate-700 group-hover:text-cyan-600">
                  {member.data}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div> */}
      {/* //Services */}
      <div>
        <div className="pt-3">
          <p className="text-cyan-800 text-4xl md:p-20 text-center text-bold font-Nunito">
            Innovation drives progress by transforming challenges into opportunities.
            It distinguishes leaders from followers, and true success lies in action rather than mere ideas.
            Embracing change fosters creativity and collaboration, paving the way for a better future.
          </p>
          <h3 className="text-center text-cyan-400 text-5xl font-semibold tracking-tight ">Our Services</h3>
          <Container>
            {/* <h2 className="section-title" id="service">
            Our Services
          </h2> */}
            <Row>{renderServices()}</Row>
          </Container>
        </div>
      </div>
      <div className="md:pb-8 bg-gradient-to-r from-gray-600 to-cyan-300">
        <div className="pb-8 pt-4">
          <div>
            <h3 className="text-center text-5xl font-semibold tracking-tight">
              Why Choose Us
            </h3>
          </div>
        </div>
        <div className="md:pl-4 md:pr-4 space-y-4 md:grid md:grid-cols-3 text-xl">
          {/* <div className="md:md:flex md:justify-between md:items-center space-y-4 space-x-4"> */}
          <div className="md:flex space-x-4  transform transition duration-700 hover:scale-95  md:h-48  md:pl-4 md:pr-4 md:w-96">
            <div className="flex justify-normal">
              <h2>01</h2>
              <div class="h-1 w-28 mt-2 bg-cyan-300"></div>
            </div>
            <div>
              <h2 className="text-lg font-semibold font-Nunito">
                High Quality Hardware
              </h2>
              <p className="font-Nunito">
                We use top-norch hardware to develop
                <br></br>the most efficient apps for our customers.
              </p>
            </div>
          </div>
          <div className="md:flex space-x-4 transform transition duration-700 hover:scale-95 md:h-48  md:pl-4 md:pr-4 md:w-96">
            <div className="flex justify-normal">
              <h2>02</h2>
              <div class="h-1 w-28 mt-2 bg-cyan-300"></div>
            </div>
            <div>
              <h2 className="text-lg font-semibold font-Nunito">
                Expertise and Experience:
              </h2>
              <p className="font-Nunito">
                Backed by a skilled IT team, we boast a track<br />
                record of success in diverse industries
              </p>
            </div>
          </div>
          <div className="md:flex space-x-4 transform transition duration-700 hover:scale-95  md:h-48  md:pl-4  md:pr-4 md:w-96">
            <div className="flex justify-normal">
              <h2>03</h2>
              <div class="h-1 w-28 mt-2 bg-cyan-300"></div>
            </div>
            <div>
              <h2 className="text-lg font-semibold">End-to-End Services:</h2>
              <p>
                By partnering with us,
                <br />
                you gain access to a single
                <br />
                point of contact for all your
                <br />
                IT requirements.
              </p>
            </div>
          </div>
          {/* </div> */}
          {/* <div className="md:md:flex md:justify-between md:items-center space-y-4 space-x-4"> */}
          <div className="md:flex space-x-4 transform transition duration-700 hover:scale-95  md:h-48 md:pl-4  md:pr-4 md:w-96">
            <div className="flex justify-normal">
              <h2>04</h2>
              <div class="h-1 w-28 mt-2 bg-cyan-300"></div>
            </div>
            <div>
              <h2 className="text-lg font-semibold">Innovative Solutions</h2>
              <p>
                Our innovative mindset
                <br />
                enables us to think outside <br />
                the box and find creative solutions to complex challenges,
                driving your
                <br />
                digital transformation forward.
              </p>
            </div>
          </div>
          <div className="md:flex space-x-4  transform transition duration-700 hover:scale-95  md:h-48 md:pr-4 md:w-96">
            <div className="flex justify-normal">
              <h2>05</h2>
              <div class="h-1 w-28 mt-2 bg-cyan-300"></div>
            </div>
            <div>
              <h2 className="text-lg font-semibold">
                Dedicated 24/7 support
              </h2>
              <p>
                You can rely on our <br />
                24/7 tech support
                <br></br>that will respond faster and help on IT problems
                encountered.
              </p>
            </div>
          </div>
          <div className="md:flex space-x-4 transform transition duration-700 hover:scale-95  md:h-48  md:pl-4 md:pr-4 md:w-96">
            <div className="flex justify-normal">
              <h2>06</h2>
              <div class="h-1 w-28 mt-2 bg-cyan-300"></div>
            </div>
            <div>
              <h2 className="text-lg font-semibold">Reliability</h2>
              <p>
                We prioritize transparency in our
                <br />
                operations and strive to build <br />
                long-term relationships based on trust.
              </p>
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>

      <div className="w-full md:h-96 md:w-full relative ">
        <p className="text-center md:pl-3 md:pr-3 pt-4 pb-4 text-4xl font-light text-cyan-800">
          Partner with us and experience the <br />
          transformative power of technology in your business.
          <br />
          Let us be your trusted IT partner, guiding you towards <br />
          a more efficient, secure, and prosperous future. <br />
          Contact us today to learn more about our services
          <br />
          and how we can assist you in achieving your technology goals.
        </p>
        <div className=" flex justify-center pt-3 pb-3">
          <a
            href="/conta"
            className="inline-flex items-center px-4 py-2 text-xl font-medium text-center text-black bg-cyan-300 rounded-lg hover:bg-cyan-500"
          >
            Contact Us
          </a>
        </div>
      </div>
      <div>
        <div className="  text-gray-200 md:flex md:justify-between md:items-center bg-gray-400 ">
          {/* <img src={Home1} alt="" className=""></img> */}
          <div className="flex justify-center items-center md:col-span-1">
            <Lottie
              animationData={animationData1}
              options={defaultOptions1}
              height={500}
              width={500}
            // className="p-4"
            />
          </div>

          <div className=" grid grid-cols-1 gap-4">
            {/* <h2 className="text-5xl uppercase font-bold text-cyan-300 ">
              Your All Stop <br />
              IT Solution
            </h2> */}
            <h2 className="text-3xl pt-2 pr-10 pl-6">
              Our portfolio is a testament to our track record <br />
              of successful project implementations. <br />
              We have collaborated with businesses across <br />
              various industries, delivering tailored IT solutions <br />
              that align with their specific goals and requirements.
              <br />
              {/* Whether it's a network infrastructure setup,
               a cybersecurity overhaul, or a cloud migration,
               we have consistently achieved positive outcomes for our clients. */}
              Our expertise and attention to detail <br />
              ensure seamless implementations that enhance <br />
              operational efficiency, improve security, <br />
              and drive business growth.
            </h2>
            <div className="flex  space-x-3 md:mt-2 pb-8 pl-8">
              <a
                href="/portfolio"
                className="inline-flex items-center px-4 py-2 text-lg font-medium text-center text-black bg-cyan-300 rounded-lg hover:bg-cyan-500 focus:ring-4 focus:outline-none focus:ring-cyan-300  dark:hover:bg-cyan-500 dark:focus:ring-cyan-500"
              >
                Our Portfolio
              </a>
            </div>
          </div>
        </div>
      </div>
      <Testimonial />
    </div>
  );
}

export default HomeUs;
