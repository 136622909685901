import React from "react";
import Misky from "./misky.jpg";
import Amir from "./ami.jpeg";
import Male from "./male.avif";

import Quotation2 from "./quote2.png";
import Quotation1 from "./quote1-removebg-preview.png";
import { Rating } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Testimonial() {
  const testData = {
    test: [
      {
        name: "Misky Nur Abdullahi",
        description: "Founder Miskynurfounation",
        image: Misky,
        message:
          "I had an amazing experience with BEYONDBITS! Their staff is enthusiastic, knowledgeable, and always willing to go the extra mile to help. They provided incredible IT solutions and were able to solve all of my IT issues quickly and efficiently. I would highly recommend this company to anyone looking for IT support.",
        rating: 4.5,
      },
      {
        name: "Amir Mohamed",
        description: "Executive Director Husika Alliance",
        image: Amir,
        message:
          "BEYONDBITS INNOVATION LTD has proven to be an outstanding provider of IT services, consistently delivering reliable and efficient solutions that effectively meet our organization's needs. Based on their exceptional performance, I confidently recommend BEYONDBITS INNOVATION LTD for any IT-related requirements.",
          rating: 4.0,
      },
      {
        name: "Dan Paul",
        description: "",
        image: Male,
        message:
          "I had the privilege of collaborating with BEYONDBITS Company on a recent project where I was tasked with creating a portfolio for their services. Working with their team was an absolute pleasure.",
          rating: 4.8,

      },
    ],
  };

  return (
    <div className=" font-Nunito">
      <div className="pt-3 pb-2 font-serif ">
        <h3 className="text-center text-3xl font-medium ">Testimonial</h3>
        <h3 className="text-center text-cyan-400 text-5xl font-semibold tracking-tight">
          What Clients Say About Us.
        </h3>
        <p className="text-center text-3xl  tracking-tight text-black ">
          Don't just take our word for it—hear what our clients have to say
          about their experience working with us.
        </p>
      </div>
      <div className="md:flex grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-10 ">
        {testData.test.map((member, index) => (
          <div key={index}>
            <div className="">
              <a
                href="#"
                className="group flex flex-col items-center md:h-[410px] md:w-fit bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl bg-gradient-to-r from-gray-500 to-cyan-300"
              >
                <img
                  className="object-cover pl-2 w-28 rounded-full md:h-28 md:w-28 md:rounded-full"
                  src={member.image}
                  alt=""
                />
                <div className="flex flex-col justify-between p-4 leading-normal">
                  <img src={Quotation1} className="h-6 w-6" alt="" />

                 {/* <div className="md:grid md:grid-cols-2 gap-2"> */}
                   <h5 className=" text-lg font-bold tracking-tight text-gray-900 dark:text-white">
                    {member.name}, {member.description}
                  </h5>
                  {/* <h5 className=" text-xl font-bold tracking-tight text-black dark:text-black">
                    {member.description}
                  </h5> */}
                  {/* </div> */}
                  <div className="flex items-center ">
                    <span className="mr-1 text-yellow-800">&#9733;</span>
                    <span className="font-semibold text-yellow-800">{member.rating}</span>
                  </div>
                  <p className="mb-3 font-normal text-sm text-gray-700 dark:text-gray-900">
                    {member.message}
                  </p>
                  <Rating value={4} readonly />
                  <div className="grid grid-cols-2 gap-1 ">
                    <div className="border-t border-dotted w-24 h-1 mt-3 bg-amber-800"></div>
                    <img src={Quotation2} className="h-6 w-6" alt="" />
                  </div>
                </div>
                <div className="absolute top-0 -inset-full h-full w-1/2 z-5 block transform -skew-x-12 bg-gradient-to-r from-transparent to-cyan-300 opacity-40 group-hover:animate-shine" />
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Testimonial;
