import React from "react";
import Hafsa from "./ict.png";
import Kaimenyi from "./kaimenyi.enc";

const Team = () => {
  const teamData = {
    team: [
      {
        name: "HAFSA HASSAN",
        image: Hafsa,
        profession: "Executive Director, Software Developer",
      },
      {
        name: "KAIMENYI MUKINDIA",
        image: Kaimenyi,
        profession: "Software Developer.",
      },
    ],
  };

  return (
    <div className="font-Nunito bg-cyan-800">
      <div className="container mx-auto py-10 px-6">
        <h2 className="text-cyan-300 text-center font-semibold text-4xl md:text-5xl">
          MEET THE TEAM
        </h2>
        <p className="text-2xl md:text-3xl text-center text-white mt-6 mb-12">
          Our team page reflects the collaborative spirit and expertise driving our IT company forward. We are comprised of dedicated professionals with diverse backgrounds and skill sets.
        </p>

        {/* Team section with grayuced gap */}
        <div className="flex flex-wrap justify-center md:justify-between gap-2 md:gap-3 pb-6">
          {teamData.team.map((member, index) => (
            <div
              key={index}
              className="bg-gradient-to-bl from-gray-400 to-cyan-800 shadow-lg rounded-lg p-4 md:p-6 w-full sm:w-80 md:w-96 animate-[fade-in_1s_ease-in-out]"
            >
              <div className="bg-white border md:p-4 p-2 rounded-lg shadow-md flex flex-col items-center">
                <img
                  className="md:w-40 w-32 h-32 md:h-40 rounded-full shadow-lg"
                  src={member.image}
                  alt={member.name}
                />
                <div className="text-center mt-4">
                  <h5 className="text-xl font-medium text-gray-900">
                    {member.name}
                  </h5>
                  <span className="text-lg text-gray-700">
                    {member.profession}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Team;
