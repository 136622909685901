import React from 'react';
import { TwitterTimelineEmbed } from 'react-twitter-embed';

function Feeds() {
  return (
    <div className=''>
      <TwitterTimelineEmbed
        sourceType="https://twitter.com/Musy_oki"
        screenName="twitter"
        options={{
          height: 300,
        }}
      />
    </div>
  );
}

export default Feeds;
