import React from "react";
import Contacts from "./con.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Contact() {
  return (
    <div className="">
      <section className="">
        <div className="relative">
          <div className="bg-gradient-to-bl from-gray-800 to-cyan-300 md:flex md:justify-between md:p-12">
            <div className="md:col-span-2 space-y-4 py-14 pl-11 ">
              <h2 className="text-5xl md:text-5xl uppercase font-bold text-cyan-300 font-Nunito">
                Let's Talk About Your Project
              </h2>
              <p className="text-2xl md:text-5xl text-zinc-200 font-Nunito pb-12">
                We care about your business and how people perceive it.
                <br />
                Don't hesitate to reach out, our door is always open and we
                would love to hear from you.

              </p>
            </div>
            <div className="md:col-span-1 content-end flex flex-col justify-end pb-10">
              <img src={Contacts} className=" md:h-full w-full" alt="" />
            </div>
          </div>
        </div>
        <div className="container md:px-12">
          <div className="block rounded-lg bg-[hsla(0,9%,21%,1)] shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-[hsla(0,0%,5%,0.7)] dark:shadow-black/20 md:py-6 md:px-2 -mt-[100px] backdrop-blur-[30px]">
            <h2 className="text-5xl text-cyan-300 text-center pb-8">
              Contact Us
            </h2>
            <div className="md:flex md:flex-wrap">
              <div className="mb-8 md:w-7/12 lg:w-2/3 xl:w-1/3">
                <form method="POST" action="https://herotofu.com/start">
                  <label className="block mb-3">
                    <span className="text-cyan-300 text-lg md:text-2xl">
                      Your name
                    </span>
                    <input
                      type="text"
                      name="name"
                      className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      placeholder="Name"
                    />
                  </label>
                  <label className="block mb-3">
                    <span className="text-cyan-300 text-lg md:text-2xl">
                      Email address
                    </span>
                    <input
                      name="email"
                      type="email"
                      className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      placeholder="joe.bloggs@example.com"
                      required
                    />
                  </label>
                  <label className="block mb-3">
                    <span className="text-cyan-300 text-lg md:text-2xl">
                      Message
                    </span>
                    <textarea
                      name="message"
                      className="w-full block mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      rows="3"
                      placeholder="Tell us what you're thinking about..."
                    ></textarea>
                  </label>
                  <div className="mb-6">
                    <button
                      type="submit"
                      className="h-10 w-full bg-cyan-300 rounded-lg transition-colors duration-150 focus:shadow-outline hover:bg-cyan-500"
                    >
                      Contact Us
                    </button>
                  </div>
                </form>
              </div>

              <div className="w-full shrink-0 grow-0 basis-auto lg:w-7/12 md:pt-12">
                <div className="md:flex flex-wrap">
                  <div className="mb-8 w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:w-full lg:px-6 xl:w-6/12">
                    <div className="md:flex items-start">
                      <div className="shrink-0">
                        <div className="inline-block rounded-md bg-primary-100 p-4 text-primary">
                          <FontAwesomeIcon
                            icon="fa-solid fa-phone"
                            size="2xl"
                            style={{ color: "#00FFFF" }}
                          />
                        </div>
                      </div>
                      <div className="ml-6 grow">
                        <p className="mb-2 font-bold text-white dark:text-cyan-300 text-xl">
                          Call or send email
                        </p>
                        <div className="grid grid-rows-2 gap-2">
                          <a
                            href="tel:+254726541551"
                            className="hover:text-cyan-300 text-neutral-500 dark:text-neutral-200"
                          >
                            +254726541551
                          </a>
                          <a
                            href="mailto:tritechhba@gmail.com"
                            className="hover:text-cyan-300 text-neutral-500 dark:text-neutral-200"
                          >
                            tritechhba@gmail.com
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="mb-8 w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:w-full lg:px-6 xl:w-6/12">
                    <div className="md:flex items-start">
                      <div className="shrink-0">
                        <div className="inline-block rounded-md bg-primary-100 p-4 text-primary">
                          <FontAwesomeIcon
                            icon="fa-solid fa-location-dot"
                            size="2xl"
                            style={{ color: "#00FFFF" }}
                          />
                        </div>
                      </div>
                      <div className="ml-6 grow">
                        <p className="mb-2 font-bold text-white dark:text-cyan-300 text-xl">
                          Where To Find Us
                        </p>
                        <a
                          href=""
                          className=" text-neutral-500 dark:text-neutral-200 hover:text-cyan-300"
                        >
                         
                          Nairobi
                        </a>
                      </div>
                    </div>
                  </div> */}
                  <div className="mb-8 w-full shrink-0 grow-0 basis-auto md:mb-0 md:w-6/12 md:px-3 lg:mb-8 lg:w-full lg:px-6 xl:w-6/12">
                    <div className="align-start md:flex">
                      <div className="shrink-0">
                        <div className="inline-block rounded-md bg-primary-100 p-4 text-primary">
                          <FontAwesomeIcon
                            icon="fa-solid fa-clock"
                            size="2xl"
                            style={{ color: "#00FFFF" }}
                          />
                        </div>
                      </div>
                      <div className="ml-6 grow">
                        <p className="mb-2 font-bold text-white dark:text-cyan-300 text-xl">
                          Time
                        </p>
                        <h2 className="hover:text-cyan-300 text-neutral-500 dark:text-neutral-200">
                          Open Everyday From 8:30AM to 5:00PM
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:w-full lg:px-6 xl:mb-8 xl:w-6/12">
                    <div className="align-start md:flex">
                      <div className="shrink-0">
                        <div className="inline-block rounded-md bg-primary-100 p-4 text-primary">
                          <FontAwesomeIcon
                            icon="fa-solid fa-hashtag"
                            size="2xl"
                            style={{ color: "#00FFFF" }}
                          />
                        </div>
                      </div>
                      <div className="ml-6 grow">
                        <p className="mb-2 font-bold text-white dark:text-cyan-300 text-xl">
                          Social Media
                        </p>
                        <div className=" space-y-4 ">
                          <a
                            href=""
                            target="_blank"
                            className="flex space-x-2"
                          >
                            <FontAwesomeIcon
                              icon="fa-brands fa-facebook"
                              style={{ color: "#00FFFF" }}
                              size=""
                            />
                            <h2 className="hover:text-cyan-300 text-neutral-500 dark:text-neutral-200">
                              FaceBook
                            </h2>
                          </a>

                          <a href="https://x.com/beyondbits_ltd?s=11" target="_blank" className="flex space-x-2">
                            <FontAwesomeIcon
                              icon="fa-brands fa-twitter"
                              style={{ color: "#00FFFF" }}
                              size=""
                            />
                            <h2 className="hover:text-cyan-300 text-neutral-500 dark:text-neutral-200">
                              Twitter
                            </h2>
                          </a>
                          <a
                            href="https://www.instagram.com/beyondbits_innovation_ltd?igsh=Y2NjN3diaDl6d3Zo&utm_source=qr"
                            target="_blank"
                            className="flex space-x-2"
                          >
                            <FontAwesomeIcon
                              icon="fa-brands fa-square-instagram"
                              style={{ color: "#00FFFF" }}
                              size=""
                            />
                            <h2 className="hover:text-cyan-300 text-neutral-500 dark:text-neutral-200">
                              Instagram
                            </h2>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Contact;
