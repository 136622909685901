// import React from 'react'
// import Accordion from 'react-bootstrap/Accordion';
// import Card from 'react-bootstrap/Card';
// import Button from 'react-bootstrap/Button';

// function Faqs() {
//   return (
//     <div className='faqs'>
//       <div>
//       <h3 className='headline'>FREQUENTLY ASKED QUESTIONS AND ANSWERS</h3>
//       </div>
//       <div className="accordion" id="accordionExample">
//   <div className="accordion-item">
//     <h2 className="accordion-header" id="headingOne">
//       <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
//         What is HBA Tri-tech
//       </button>
//     </h2>
//     <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
//       <div className="accordion-body">
//         <p>HBA Tri-Tech is a computer services company with one goal :To turn your tech dreams into reality</p>
//       </div>
//     </div>
//   </div>
//   <br/>
//   <div className="accordion-item">
//     <h2 className="accordion-header" id="headingTwo">
//       <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
//        What Services We Offer

//       </button>
//     </h2>
//     <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
//       <div className="accordion-body">
//        <ul className='answers'>
//         <li>Software development</li>
//         <li>Network Configuration</li>
//         <li>Web Development</li>
//         <li>Database Management</li>
//         <li>IT Consulting</li>
//         <li>Web Hosting</li>
//         <li>Software Installation and Support</li>
//         <li>Education and Training</li>
//        </ul>
//       </div>
//     </div>
//   </div>
//   <div className="accordion-item">
//     <h2 className="accordion-header" id="headingThree">
//       <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
//         Where we are located
//       </button>
//     </h2>
//     <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
//       <div className="accordion-body">
//        <p>We are located along Moi Avenue .Trust Properties Building opposite BIHI Tower  </p>
//       </div>
//     </div>
//   </div>
// </div>
//       </div>
//   )
// }
// export default Faqs

import React, { useState } from "react";
import Lottie from "react-lottie";
import animationData2 from "./107275-question-and-answer-q-a.json";
function Faqs() {
  const defaultOptions1 = {
    loop: true,
    autoplay: true,
    animationData: animationData2,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const [activeIndex, setActiveIndex] = useState(null);
  const faqs = [
    {
      id: 1,
      question: " What is BeyondBits Innovation Ltd?",
      answer:
        "BeyondBits Innovation Ltd is a forward-thinking technology company dedicated to crafting innovative solutions that empower businesses and enhance user experiences.",
    },
    {
      id: 2,
      question: "  What Services We Offer?",
      answer:
        "Our IT company offers a wide range of services including software development, web development, mobile app development, IT consulting, Web hosting, network infrastructure setup and management, database management, IT support and maintenance, and more.",
    },
    {
      id: 2,
      question: "  Where are we Located?",
      answer:
        "Moi avenue  Nairobi. ",
    },
    {
      id: 3,
      question: "  Can you handle projects of all sizes?",
      answer:
        "Yes, we have the capability to handle projects of various sizes, ranging from small businesses to large enterprises. Our team is flexible and adaptable, and we can scale our resources to meet the needs of the project. ",
    },
    {
      id: 3,
      question: "  How do you ensure the security of our data?",
      answer:
        "We take data security very seriously. We implement industry best practices and adhere to strict security protocols to protect your data. This includes encryption, secure coding practices, regular security audits and vulnerability assessments. ",
    },
  ];

  const toggleFAQ = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className="bg-cyan-800 font-Nunito">
      <div>       <h2 className="text-5xl text-center font-bold mb-4 text-cyan-400 p-4">Frequently Asked Questions</h2></div>
      <div className="flex">
        <Lottie
          animationData={animationData2}
          options={defaultOptions1}
          height={300}
          width={400}
          className="p-4"
        />
      </div>

      <div className="container mx-auto px-4 py-8">

        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <div
              className="bg-white rounded-lg shadow-lg p-4"
              key={faq.id}
              onClick={() => toggleFAQ(index)}
            >
              <div className="flex items-center justify-between">
                <h3 className="text-lg font-semibold">{faq.question}</h3>
                <svg
                  className={`${
                    activeIndex === index ? "transform rotate-180" : ""
                  } w-6 h-6`}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <polyline points="6 9 12 15 18 9" />
                </svg>
              </div>
              {activeIndex === index && (
                <p className="text-gray-700 mt-2">{faq.answer}</p>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Faqs;
