import React from "react";
// import Contact from "./tech1.avif";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Hafsa from "./hafsa.png";
import Mission from "./missi.png";
import Home from "./home.avif";
import Vission from "./viss.png";
import Objective from "./objec.png";
import Lottie from "react-lottie";
import animationData2 from "./63257-contact-us.json";

function AboutUs({ name, image, data }) {
  const defaultOptions1 = {
    loop: true,
    autoplay: true,
    animationData: animationData2,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const values = {
    value: [
      {
        name: "OUR MISSION",
        image: Mission,
        data: " To ignite innovation that transforms challenges into opportunities.",
      },
      {
        name: "OUR VISION",
        image: Vission,
        data: " To shape a future where technology weaves seamless connections in every life.",
      },
      {
        name: "OUR OBJECTIVES",
        image: Objective,
        data: " Craft pioneering solutions, equip minds to realize visions, lead a greener tech landscape, and unite talents for impactful change. ",
      },
    ],
  };
  return (
    <div className="font-Nunito ">
      <div className=" md:flex md:justify-between md:items-center  bg-gradient-to-br  from-gray-800 to-cyan-300  ">
        <div className="flex  w-full">
          <img src={Home} className="rounded-full h-96 " alt="" />
          {/* <Lottie
            animationData={animationData2}
            options={defaultOptions1}
            max-height={400}
            max-width={200}
            className=""
          /> */}

          {/* <img src={Contact} className="rounded-tl-full h-64" alt="" /> */}
        </div>
        <div className="flex flex-col space-y-6 pl-8 pb-4 pr-8 pt-5">
          <h2 className="text-5xl font-bold text-center text-cyan-300 ">
            Who we are
          </h2>
          <p className="text-3xl text-bold text-white text-center pl-2 ">
          BeyondBits Innovation Ltd. is a forward-thinking technology company dedicated to delivering cutting-edge digital solutions. We specialize in web hosting, software development, and innovative tech services designed to empower businesses and individuals. Our mission is to harness technology to create seamless, efficient, and impactful solutions that drive success and foster growth in an ever-evolving digital landscape.
        
          </p>
        </div>
      </div>

      <div className=" md:flex md:justify-between md:items-center space-y-4 space-x-4 md:px-12 ">
        {values.value.map((member, index) => (
          // <div className="flex flex-col items-center pb-10">
          <div
            key={index}
            className="   bg-white shadow-md rounded-lg p-3 animate-[fade-in_1s_ease-in-out]  transform transition duration-700 hover:scale-95 md:w-96 "
          >
            <div className=" bg-white border border-cyan-800 rounded-lg shadow dark:bg-gray-800 dark:border-cyan-900">
              <a href="#">
                <img
                  class="rounded-t-lg md:h-64 w-80 transition duration-300 ease-in-out hover:scale-95 "
                  src={member.image}
                  alt=""
                />
              </a>
              <div className="p-3 md:h-52 group block  mx-auto rounded-lg bg-white ring-1 ring-slate-900/5 shadow-lg  hover:bg-sky-500 hover:ring-sky-500">
                <div class="">
                  <svg
                    class="h-6 w-6 stroke-sky-500 group-hover:stroke-cyan-400"
                    fill="none"
                    viewBox="0 0 24 24"
                  ></svg>
                  <a href="#">
                    <h5 class="mb-2 text-2xl font-bold tracking-tight text-cyan-300 t group-hover:text-cyan-600 ">
                      {member.name}
                    </h5>
                  </a>
                </div>
                <p className="mb-4 font-light text-xl dark:text-gray-700 text-slate-700 group-hover:text-cyan-600">
                  {member.data}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="  text-gray-200 md:flex md:justify-between md:items-center bg-gradient-to-br  from-gray-800 to-cyan-300 ">
          <img src={Hafsa} alt="" className=" "></img>
        

          <div className=" grid grid-cols-1 gap-4">
          
            <h2 className="text-3xl pt-2 pr-10 pl-6">
            Hamsa Hassan Adan is the founder of BeyondBits Innovation Ltd and a dedicated software engineer with a robust background in both front-end and back-end development. Known for thriving under pressure, Hamsa effectively tackles complex projects by combining creativity with technical proficiency. With extensive experience in various ICT roles, they have honed skills in software development, database management, and user support. Passionate about software development, data science, and artificial intelligence, Hamsa is committed to leveraging technology to drive innovation and empower businesses.
            </h2>
            
          </div>
        </div>

    </div>
  );
}

export default AboutUs;
